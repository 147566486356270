import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CommentPolicy = () => {
  return (
    <Layout>
      <Container className="text-white mt-5">
        <Row className="justify-content-center">
          <Col xs={12} className="text-center mb-5">
            <h1 className="text-info">Comment Policy</h1>
            <p>This is a friendly and respectful community. Any personal attacks, hate speech, or profanity will not be tolerated.</p>
          </Col>
          <Col xs={12}>
            <h2 className="fs-4 fw-bolder text-info">Rules</h2>
            <p>
              Name-calling, personal attacks, spamming, excessive self-promotion, condescending pomposity, general assiness, racism,
              sexism, any-other-ism, homophobia, off-topicness, acrophobia, ailurophobia and destructive (versus constructive) criticism
              will get you <span className="text-danger fw-bolder">BANNED</span> from the party as will creating multiple accounts, actor abuse along with any other trollish behaviour.
              All comments in <span className="text-warning fw-bolder">ENGLISH</span> so that I can respond please.</p>
            <h2 className="fs-4 fw-bolder text-info">Flagging Comments</h2>
            <p>
              If you have a problem with a comment, <span className="text-warning fw-bolder">DO NOT REPLY TO IT</span>. Instead, click the Dropdown Icon/Arrow to the right of the offending
              comment and click the Flag link from the dropdown. Our Moderation Team will then deal with any inappropriate comments.
            </p>
            <h2 className="fs-4 fw-bolder text-info">Problems, Issues and Suggestions</h2>
            <p>
              If you have any suggestions or feedback on the site please use the <a href="https://corydorfner.com/contact">Contact Form</a>.
              If your Disqus account has been blocked from commenting you can try using the contact form to request an unblock.
              Please be sure to provide the link to your Disqus profile and the email address used to register with Disqus or we will be unable to unblock you.
            </p>
            <Col xs={12} className="text-center">
              <Link to="/blog">
                <Button variant="info">Return to my blog</Button>
              </Link>
            </Col>

          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = (props) => {
  const pageSeo = {
    title: "Comment Policy",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

export default CommentPolicy